
















































import Vue from "vue";
import { RequestJobCostingMeatroomProduceUpdate } from "@/models/interface-v2/job-costing-meatroom.interface";

export default Vue.extend({
  name: "ModalConfirmJC",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    products: {
      type: Array as () => RequestJobCostingMeatroomProduceUpdate[],
      default: undefined,
    },
  },
  methods: {
    close(): void {
      this.$emit("input", false);
    },
    save(): void {
      this.$emit("on-save");
    },
  },
});
