var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: {
        visible: _vm.value,
        "destroy-on-close": true,
        width: 650,
        title: _vm.$t("lbl_confirmation")
      }
    },
    [
      _c("p", { staticClass: "text-bold" }, [
        _vm._v(" " + _vm._s(_vm.$t("notif_fill_in")) + " ")
      ]),
      _c(
        "a-table",
        { attrs: { "data-source": _vm.products, pagination: false } },
        [
          _c(
            "a-table-column",
            {
              key: "no",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text) {
                    return _c("span", {}, [_vm._v(_vm._s(text.no))])
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_number_short")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "initialWeightQty",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text) {
                    return _c("span", {}, [
                      _vm._v(_vm._s(text.initialWeightQty))
                    ])
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_initial_qty")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "totalQty",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text) {
                    return _c("span", {}, [_vm._v(_vm._s(text.totalQty))])
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_total_processed")))
              ])
            ]
          ),
          _c(
            "a-table-column",
            {
              key: "outstandingQty",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(text) {
                    return _c("span", {}, [_vm._v(_vm._s(text.outstandingQty))])
                  }
                }
              ])
            },
            [
              _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                _vm._v(_vm._s(_vm.$t("lbl_outstanding_qty")))
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer" },
        [
          _c("a-button", { key: "back", on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")
          ]),
          _c(
            "a-button",
            {
              key: "save",
              attrs: { type: "primary" },
              on: { click: _vm.save }
            },
            [_vm._v(" " + _vm._s(_vm.$t("lbl_yes")) + " ")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }